define("discourse/plugins/discourse-calendar/discourse/connectors/user-custom-preferences/region", ["exports", "@ember/object", "discourse/plugins/discourse-calendar/discourse/lib/regions"], function (_exports, _object, _regions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    setupComponent(args, component) {
      var _obj;
      component.setProperties((_obj = {
        onChange(value) {
          this.model.set("custom_fields.holidays-region", value);
        },
        useCurrentRegion() {
          this.model.set("custom_fields.holidays-region", _regions.TIME_ZONE_TO_REGION[moment.tz.guess()] || "us");
        }
      }, (_applyDecoratedDescriptor(_obj, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj), _applyDecoratedDescriptor(_obj, "useCurrentRegion", [_object.action], Object.getOwnPropertyDescriptor(_obj, "useCurrentRegion"), _obj)), _obj));
    },
    shouldRender(args, component) {
      return component.siteSettings.calendar_enabled;
    }
  };
});
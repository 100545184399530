define("discourse/plugins/discourse-calendar/discourse/controllers/admin-plugins-calendar", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    selectedRegion: null,
    loading: false,
    async getHolidays(region_code) {
      if (this.loading) {
        return;
      }
      this.set("selectedRegion", region_code);
      this.set("loading", true);
      return (0, _ajax.ajax)(`/admin/discourse-calendar/holiday-regions/${region_code}/holidays`).then(response => {
        this.model.set("holidays", response.holidays);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
  }, (_applyDecoratedDescriptor(_obj, "getHolidays", [_object.action], Object.getOwnPropertyDescriptor(_obj, "getHolidays"), _obj)), _obj));
});